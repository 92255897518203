import {defineStore} from 'pinia'
import JwtService from "@/core/services/JwtService";
import {User} from "@/store/modules/AuthModule";
import ApiService from "@/core/services/ApiService";
import {useRouter} from "vue-router";

export const useUserStore = defineStore('userStore', {
  state: () => {
    return {
      user: {} as User,
      isAuthenticated: !!JwtService.getToken(),
      errors: {},
    }
  },
  actions: {
    isUserAuthenticated() {
      return !!JwtService.getToken();
    },
    logout() {
      this.isAuthenticated = false;
      this.user = {} as User;
      this.errors = [];
      JwtService.destroyToken();
    },
    login(credentials) {
      return ApiService.post("login", credentials)
        .then(({data}) => {
          if (!data.error) {
            this.isAuthenticated = true;
            this.user = data.user;
            this.errors = {};
            JwtService.saveToken(data.user.access_token);
          } else {
            this.errors = {...data.message.errors};
          }
        })
        .catch(({response}) => {
          this.errors = {...response.data.errors};
        });
    },

    loginFromKey(user) {
      this.isAuthenticated = true;
      this.user = user;
      this.errors = {};
      JwtService.saveToken(user.access_token);
    },

    verifyToken(payload) {
      ApiService.setHeader();
      ApiService.post("verify_token", payload)
        .then(({data}) => {
          this.isAuthenticated = true;
          this.user = data.user;
          this.errors = {};
          JwtService.saveToken(data.user.access_token);
        })
        .catch(({response}) => {
          this.errors = {...response.data.errors};
          this.logout();
          const router = useRouter();
          router.push({name: 'login'});
        });
    }
  },
})
