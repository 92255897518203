import {createApp} from "vue";
import App from "./App.vue";
import VueClipboard from 'vue3-clipboard'
import mitt from 'mitt';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import {VueDraggableNext} from 'vue-draggable-next'
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';

const emitter = mitt();
// @ts-ignore
window.Pusher = require('pusher-js');

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";

// @ts-ignore
import ElementPlus, {dayjs} from 'element-plus'
// @ts-ignore
import ro from 'element-plus/es/locale/lang/ro'
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
})

import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import {initApexCharts} from "@/core/plugins/apexcharts";
import {initInlineSvg} from "@/core/plugins/inline-svg";
import {initVeeValidate} from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";

const app = createApp(App);
import {createPinia} from 'pinia'

const pinia = createPinia()

app.use(router);
app.use(pinia)
app.use(ElementPlus, {
  locale: ro,
});
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: false,
})
app.component('draggable', VueDraggableNext);

app.mixin({
  methods: {
    translateDay(day) {
      switch (day) {
        case 1:
          return 'Luni';
        case 2:
          return 'Marti';
        case 3:
          return 'Miercuri';
        case 4:
          return 'Joi';
        case 5:
          return 'Vineri';
        case 6:
          return 'Sambata';
        case 7:
          return 'Duminica';
      }
    },

    translateWeek(week) {
      switch (week) {
        case 0:
          return 'Fiecare saptamina';
        case 1:
          return 'Saptamini impare';
        case 2:
          return 'Saptamini pare';
      }
    },
  }
})

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.config.globalProperties.emitter = emitter;
app.use(Toast, {});
app.use(VCalendar, {})

app.mount("#app");
