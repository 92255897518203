import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from "vue-router";
import store from "@/store";
import {Mutations, Actions} from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import {useUserStore} from "@/pinia/user";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/bookings",
        name: "bookings",
        component: () => import("@/views/bookings/booking-list.vue"),
      },
      {
        path: "/users",
        name: "Administratori",
        component: () => import("@/views/users/users-list.vue"),
      },
      {
        path: "/buses",
        name: "buses",
        component: () => import("@/views/buses/buses-list.vue"),
      },
      {
        path: "/buses/:id",
        name: "buses.details",
        component: () => import("@/views/buses/bus-details.vue"),
      },
      {
        path: "/companies",
        name: "companies",
        component: () => import("@/views/companies/index.vue"),
      },
      {
        path: "/companies/:id",
        name: "companies.edit",
        component: () => import("@/views/companies/edit.vue"),
      },
      {
        path: "/create-booking/",
        name: "create-booking.step1",
        component: () => import("@/views/create-booking/step1.vue"),
      },
      {
        path: "/create-booking/step2/:fromId/:toId/:dateFrom/:busId/:companyId",
        name: "create-booking.step2",
        component: () => import("@/views/create-booking/step2.vue"),
      },
      {
        path: "/create-booking/step3/:fromId/:toId/:dateFrom/:busId/:companyId",
        name: "create-booking.step3",
        component: () => import("@/views/create-booking/step3.vue"),
      },
      {
        path: "/create-booking/step3bs/:fromId/:toId/:dateFrom/:busId/:companyId",
        name: "create-booking.step3bs",
        component: () => import("@/views/create-booking/step3bs.vue"),
      },
      {
        path: "/create-booking/step4/:fromId/:toId/:dateFrom/:busId/:companyId",
        name: "create-booking.step4",
        component: () => import("@/views/create-booking/step4.vue"),
      },
      {
        path: "/bus-templates",
        name: "bus-templates",
        component: () => import("@/views/bus-templates/template-list.vue"),
      },
      {
        path: "/bus-templates/create",
        name: "bus-templates.create",
        component: () => import("@/views/bus-templates/template-create.vue"),
      },
      {
        path: "/bus-templates/:id",
        name: "bus-templates.edit",
        component: () => import("@/views/bus-templates/template-item.vue"),
      },
      {
        path: "/connections/",
        name: "connections",
        component: () => import("@/views/connections/index.vue"),
      },
      {
        path: "/connections/create",
        name: "connections.create",
        component: () => import("@/views/connections/create.vue"),
      },
      {
        path: "/routes/",
        name: "routes",
        component: () => import("@/views/routes/route-list.vue"),
      },
      {
        path: "/routes/:id",
        name: "routes.edit",
        component: () => import("@/views/routes/route-item.vue"),
      },
      {
        path: "/routes/create",
        name: "routes.create",
        component: () => import("@/views/routes/route-create.vue"),
      },
      {
        path: "/route-passengers/",
        name: "route-passengers",
        component: () => import("@/views/route-passengers/route-passengers-list.vue"),
      },
      {
        path: "/price-types/",
        name: "price-types",
        component: () => import("@/views/price-types/price-types-list.vue"),
      },
      {
        path: "/price-types/create",
        name: "price-types.create",
        component: () => import("@/views/price-types/price-types-create.vue"),
      },
      {
        path: "/price-types/:id",
        name: "price-types.edit",
        component: () => import("@/views/price-types/price-types-item.vue"),
      },
      {
        path: "/properties/",
        name: "properties",
        component: () => import("@/views/properties/list.vue"),
      },
      {
        path: "/properties/:id",
        name: "properties.edit",
        component: () => import("@/views/properties/item.vue"),
      },
      {
        path: "/properties/create",
        name: "properties.create",
        component: () => import("@/views/properties/create.vue"),
      },
      {
        path: "/customers",
        name: "customers.list",
        component: () => import("@/views/customers/list.vue"),
      },
      {
        path: "/customers/:id",
        name: "customers.edit",
        component: () => import("@/views/customers/item.vue"),
      },
      {
        path: "/report",
        name: "report",
        component: () => import("@/views/report/index.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/index.vue"),
      },
      {
        path: "/static",
        name: "static.index",
        component: () => import("@/views/static/index.vue"),
      },
      {
        path: "/static/create",
        name: "static.create",
        component: () => import("@/views/static/create.vue"),
      },
      {
        path: "/static/:id",
        name: "static.edit",
        component: () => import("@/views/static/edit.vue"),
      },
      {
        path: "/countries",
        name: "countries.index",
        component: () => import("@/views/countries/index.vue"),
      },

      {
        path: "/stations",
        name: "stations.index",
        component: () => import("@/views/stations/index.vue"),
      },
      {
        path: "/reviews",
        name: "reviews.index",
        component: () => import("@/views/reviews/index.vue"),
      },
      {
        path: "/sms-history",
        name: "sms-history.index",
        component: () => import("@/views/sms-history/index.vue"),
      },
      {
        path: "/bussystem-routes",
        name: "bussystem-routes.index",
        component: () => import("@/views/bus-system-routes/index.vue"),
      },

    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/redirect",
        name: "redirect",
        component: () =>
          import("@/views/Redirect.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Driver.vue"),
    children: [
      {
        path: "/driver",
        name: "driver",
        component: () =>
          import("@/views/driver/index.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  const userStore = useUserStore();
  userStore.verifyToken({api_token: JwtService.getToken()})

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
