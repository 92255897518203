import {defineStore} from 'pinia'
import JwtService from "@/core/services/JwtService";
import {Modal} from "bootstrap";
import {POSITION, useToast} from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import useEmitter from "@/composables/useEmitter";
import {ElMessage, ElNotification} from "element-plus";

export const useGlobalStore = defineStore('globalStore', {
  state: () => {
    return {
      pageTitle: '',
      pageBreadcrumbPath: [],
      activeBookingId: null,
      activeBookingHistoryIdMutation: null,
      activeBookingHistoryId: 0,
      catchError: [],
      asideMenuIsOpen: false,
      activePhoneCall: null,
      // activePhoneCall: <any>{
      //   "callId": 77800,
      //   "phoneNumber": "+37369222269",
      //   "name": "test test",
      //   "history": [{"id": 77795, "name": "Tudor", "date": "3 secunde", "comment": ""}, {
      //     "id": 77796,
      //     "name": "Tudor",
      //     "date": "3 secunde",
      //     "comment": ""
      //   }, {"id": 77797, "name": "Tudor", "date": "3 secunde", "comment": ""}, {
      //     "id": 77798,
      //     "name": "Tudor",
      //     "date": "3 secunde",
      //     "comment": ""
      //   }, {"id": 77799, "name": "Tudor", "date": "3 secunde", "comment": ""}, {
      //     "id": 77794,
      //     "name": "Tudor",
      //     "date": "8 minute",
      //     "comment": ""
      //   }, {"id": 77793, "name": "Tudor", "date": "9 minute", "comment": ""}, {
      //     "id": 77792,
      //     "name": "Tudor",
      //     "date": "1 oră",
      //     "comment": ""
      //   }],
      //   "bookingCount":33,
      //   "customerId":92729
      // },
      wsStatus: true,

    }
  },
  actions: {
    addBodyClassName(className) {
      document.body.classList.add(className);
    },
    removeBodyClassName(className) {
      document.body.classList.remove(className);
    },
    addBodyAttribute(attribute, value) {
      document.body.setAttribute(attribute, value);
    },
    removeBodyAttribute(attribute) {
      document.body.removeAttribute(attribute);
    },
    openBooking(id) {
      // this.activeBookingId = id;
      // if (id) {
      //   // @ts-ignore
      //   const modal = new Modal(document.getElementById("kt_modal_edit_booking"));
      //   modal.show();
      // }
      // const emitter = useEmitter();
      // emitter.emit('open-bookings-modal', {bookingId: id});
      // this.openBook(id);
    },
    catchErrors(errors) {
      for (const [key, value] of Object.entries(errors.response.data.errors)) {

        this.catchError.push({
          // @ts-ignore
          message: value,
        })
      }
    },
    addNotificationSuccess(message) {
      // const toast = useToast();

      // toast.success(message, {
      //   timeout: 7000,
      //   position: POSITION.BOTTOM_LEFT
      // });
      // ElMessage({
      //   message: message,
      //   type: 'success',
      //   duration: 7000
      // })
      ElNotification({
        // title: 'Custom Position',
        message: message,
        position: 'bottom-left',
        type: 'success',
      })
    },
    addNotificationError(message) {
      // const toast = useToast();

      // toast.error(message, {
      //   timeout: 7000,
      //   position: POSITION.BOTTOM_LEFT
      // });
      // ElMessage({
      //   message: message,
      //   type: 'error',
      //   duration: 7000
      // })
      ElNotification({
        // title: 'Custom Position',
        message: message,
        position: 'bottom-left',
        type: 'error',
      })
    },
    addNotificationInfo(message) {
      // const toast = useToast();

      // toast.error(message, {
      //   timeout: 7000,
      //   position: POSITION.BOTTOM_LEFT
      // });
      // ElMessage({
      //   message: message,
      //   type: 'info',
      //   duration: 7000
      // })
      ElNotification({
        // title: 'Custom Position',
        message: message,
        position: 'bottom-left',
        type: 'info',
      })
    },

    toggleAsideMenu(toggle = true) {
      if (toggle) {
        this.asideMenuIsOpen = !this.asideMenuIsOpen;
      }
      const body = document.querySelector('body');
      if (!body) {
        return;
      }
      if (!this.asideMenuIsOpen) {
        body.setAttribute('data-kt-aside-minimize', 'on');
      } else {
        body.removeAttribute('data-kt-aside-minimize');
      }
      localStorage.setItem('asideMenuIsOpen', this.asideMenuIsOpen ? '1' : '0');
    },
    getAsideMenuIsOpen() {
      this.asideMenuIsOpen = localStorage.getItem('asideMenuIsOpen') === '1';
      this.toggleAsideMenu(false);
    },

    call(phoneNumber) {
      const globalStore = useGlobalStore();
      return ApiService.post('/users/call', {phone_number: phoneNumber})
        .then(() => {
          globalStore.addNotificationSuccess('Verifica daca aplicatia este deschisa pe telefonul tau.')
        })
        .catch(() => {
          globalStore.addNotificationError('A aparut o eroare la apelare.')
        })
    },
  },
})
